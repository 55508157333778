/* Display flexs' */
.main-modal-container {
  display: flex;
}

.main-modal-container {
  width: 100%;
  min-height: 100%;
  background: rgba(122, 122, 122, 0.3);
  position: absolute;
  top: 0;
  z-index: 30;
  align-items: center;
  justify-content: center;
}

.form-modal {
  position: fixed;
  top: 25%;
  left: 25%;
  overflow: hidden;
  border-radius: 1rem;
  width: 50%;
}

.form-modal-header {
  padding: 1rem;
}

.form-modal-message {
  padding: 3rem;
  font-size: 1.2rem;
  text-align: center;
}

.form-modal-footer {
  padding: 1rem;
  text-align: right;
}

.close-btn {
  padding: 0.85rem 2rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
