/* display flexs' */
.modal-main-container,
.modal-top-panel,
.filing-info-container,
.creation-type-cards-container,
.start-filing-btn-wrapper {
  display: flex;
}

.modal-main-container {
  position: absolute;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(122, 122, 122, 0.3);
  z-index: 30;
  top: 0;
}

.modal {
  /* height: 80%; */
  border-radius: 1rem;
}

.modal-top-panel {
  justify-content: space-between;
  align-items: center;
  padding: 0.85rem 1rem;
}

.modal-close-btn {
  font-size: 1.5rem;
  cursor: pointer;
}

.current-tax-year-wrapper {
  text-align: center;
  padding: 1rem 0 0.25rem;
}

.filing-type {
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 1.2rem;
}

.filing-type > span {
  font-weight: bold;
}

.file-btn-wrapper {
  text-align: center;
  margin-bottom: 0.25rem;
}

.file-btn-wrapper > button {
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
}

.filing-due-date {
  text-align: center;
}

.filing-info-container {
  height: 70%;
  flex-direction: column;
  justify-content: center;
}

/* Form create component  */
.form-create-heading-wrapper {
  padding: 0.5rem 1rem;
}

.creation-type-cards-container {
  padding: 0.5rem 1rem;
  gap: 1rem;
}

/* entry cards  */
.card {
  box-shadow: 5px 5px 15px rgba(122, 122, 122, 0.3);
  padding: 1rem;
  cursor: pointer;
  color: black;
}

.card-heading {
  font-size: 1.25rem;
  padding: 0.25rem 0;
  text-align: left;
}

.card-description {
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
}

.form-icon {
  font-size: 4rem;
}

.start-filing-btn-wrapper {
  justify-content: space-between;
  align-items: center;
}
