/* Display fles' */
.btn-main-container,
.tin-mask-container {
  display: flex;
  position: relative;
  /* position: fixed; */
}

.btn-main-container {
  justify-content: center;
  padding: 1.85rem 0 0;
  gap: 1.5rem;
}

#NEXT,
#EDIT,
.continue-btn {
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  font-size: 1.2rem;
}

.next-btn-wrapper {
  position: relative;
}

.next-disclaimer {
  position: absolute;
  top: 0.25rem;
  color: orange;
  font-weight: bold;
  /* padding: 0.25rem 0; */
}

.continue-btn {
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  position: absolute;
  right: 3rem;
  /* top: 0.5rem; */
}
