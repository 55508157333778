.cred-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.button-container{
    text-align: center;
    padding: 0.5rem 1.85rem;
    /* border: 2px solid; */
    border-radius: 2rem;
    position: relative;
    overflow: hidden;
}

/* .button-container:last-child{
    background: orange;
} */

.button-container:last-child > a{
    color: white;
}

/* adding animation on hover over buttons  */
.button-filler{
 position: absolute;
 /* background: orange; */
 left: 0;
 bottom: 0;
 width: 100%;
 transition: height 0.3s;
 z-index: -1;   
}

.terms-and-conditions-container{
    text-align: center;
    padding: 0.35rem 0;
}

.terms-and-conditions-container > a{
    text-decoration: underline;
}