/* Display flexs' */
.bulk-action-container
{
    display: flex;
    justify-content: space-evenly;
    gap: 1.5rem;
    padding: 1rem 2rem;
}

#DOWNLOAD,
#BULK_REVIEW,
#BULK_UNREVIEW,
#BULK_DELETE
{
    cursor: pointer;
    font-size: 1.5rem;
}

.icon-deactivate
{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.individual-action-container
{
    position: relative;
}

.hoverable-name
{
    position: absolute;
    top: -2rem;
    padding: 0.1rem 0.5rem;
}