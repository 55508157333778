/* Display flexs' */
.cl-heading,
.cl-header-title-wrapper {
  display: flex;
}

.cl-main-container {
  position: relative;
  border-radius: 0.5rem;
  /* overflow: hidden; */
}

.cl-body {
  transition: max-height 0.7s;
  /* overflow: hidden; */
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 0 0 0.5rem 0.5rem;
}

.cl-heading {
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}

.cl-header-title-wrapper {
  align-items: center;
}

.cl-arrow {
  transition: transform 700ms;
}
