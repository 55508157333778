/* Display flexs' */
.history-filter-panel,
.year-and-payer,
.bulk-download,
.year,
.payer {
  display: flex;
}

.history-filter-panel {
  padding: 2rem;
  justify-content: space-between;
}

.year-and-payer {
  width: 30%;
  gap: 1rem;
}

.year,
.payer {
  width: 50%;
  flex-direction: column;
  gap: 0.25rem;
}

.year > label,
.payer > label {
  font-weight: bold;
}

.bulk-download {
  align-items: end;
  width: 20%;
}

.bulk-download > div {
  width: 100%;
}
