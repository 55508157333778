.dd-container {
  position: absolute;
  /* bottom: -6.25rem; */
  /* width: 100%; */
  max-height: 30rem;
  overflow: auto;
  z-index: 1;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
}

.dd-item {
  padding: 0.5rem 1rem;
  font-size: 1.05rem;
  cursor: pointer;
  display: flex;
  gap: 1rem;
}
