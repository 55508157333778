/* Display flexs */
.pse-details-wrapper,
.pse-details-wrapper > div {
  display: flex;
}

.pse-details-heading {
  padding: 1rem;
  font-weight: bold;
}

.pse-details-wrapper > div {
  flex-direction: column;
  padding: 0 1rem 1rem;
  width: 33.3%;
}

/* .pse-details-wrapper > div:nth-child(1) {
  width: 40%;
}

.pse-details-wrapper > div:nth-child(2),
.pse-details-wrapper > div:nth-child(3) {
  width: 30%;
} */

.pse-details-wrapper > div > input {
  padding: 0.5rem 1rem;
}
