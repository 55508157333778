th, td{
  border: 1px solid rgba(122, 122, 122,0.3)
}

table{
  width: 100%;
  border-radius: 0.5rem;
  /* overflow: hidden; */
  /* box-shadow: 2px 2px 10px rgba(0,0,0,0.3); */
  border-collapse:separate;
}

th:first-child,
td:first-child{
  text-align: center;
  padding-left: 0.95rem;
}

th{
  text-align: left;
  padding: 0.5rem;
  font-size: 1.05rem;
  background: #F6F4EB;
  height: 100%;
}


td{
  padding: 0.25rem 0.5rem;
}

td > div > p{
  margin: 0.25rem 0;
}

td > div > p:last-child{
  font-size: 0.9rem;
  color: rgb(122, 122, 122);
}

.rec-record{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rec-record:hover{
  background: rgba(135, 207, 235, 0.336);
}

.review-check{
  display: flex;
  align-items: center;
  color: #3CCF4E;
}

.checkbox-container{
  width: 3.5rem;
}