/* display flexs' */
.user-initial{
    display: flex;
}

.user-account-info{
    position: relative;
}

.user-initial{
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 100%;
}

.user-profile-icon{
    width: 100%;
}