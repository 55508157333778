/* Display flexs */
.od-price-breakage,
.od-total-amount-child,
.payment-disclaimer
{
    display: flex;
}

.order-details {
    position: fixed;
    width: 35%;
    /* height: 100vh; */
    text-align: center;
    padding: 3rem 1rem;
}
.od-heading
{
    text-align: left;
    text-decoration: underline;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.od-body, 
.od-total-amount
{
    border-radius: 1rem;
    overflow: hidden;
}

.od-body,
.od-total-amount
{
    text-align: left;
    padding: 0.5rem 0;
    font-size: 1rem;
}

.od-price-breakage, .od-total-amount-child
{
    padding: 0 2rem;
}

.od-body
{
    padding: 0;
}

.od-price-breakage
{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.od-price-breakage:last-child
{
    padding-bottom: 1rem;
}

/* .od-price-breakage:first-child
{
    padding-top: 1rem;
} */

.od-price-breakage
{
    justify-content: space-between;
}

.od-price-breakage > p:last-child,
.od-total-amount-child > p:last-child,
.od-total-amount-child > h2:last-child
{
    width: 7.25rem;
    text-align: right;
}

.od-price-breakage > p:nth-child(2)
{
    width: 6rem;
    text-align: center;
}

.od-price-breakage>p:nth-child(1) {
    width: 13rem;
    text-align: left;
}

.od-total-amount
{
    margin-top: 1rem;
}

.od-total-amount-child
{
    justify-content: space-between;
    margin: 0.85rem 0;
}

.payment-disclaimer
{
    margin: 1rem 0 0;
    padding: 1rem;
    /* text-align: left; */
    text-align: justify;
    gap: 1rem;
    font-size: 1rem;
    cursor: pointer;
}