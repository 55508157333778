/* Display flexs' */
.verification-container-main {
  display: flex;
}

.verification-container-main {
  width: 50%;
  flex-direction: column;
  align-items: center;
}
