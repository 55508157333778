/* display flexs' */
.account-container,
.rec-account-heading,
.leave-blank-disclaimer{
    display: flex;
}

.account-container{
    justify-content: space-between;
}

.account-number-wrraper{
    padding: 0.85rem 1rem;
    width: 100%;
    position: relative;
}

.rec-account-heading{
    gap: 0.5rem;
    padding: 0 0 0.25rem;
}

.rec-account-heading > p {
    cursor: pointer;
}

.leave-blank-disclaimer{
    flex-direction: column;
    position: absolute;
    width: 25rem;
    top: -3.25rem;
    align-items: center;
    left: 0;
}

.leave-blank-disclaimer-heading{
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.5rem;
}

/* Down triangle */
.triangle-down{
    width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	/* border-top: 10px solid #555; */
    position: absolute;
    bottom: -0.65rem;
}