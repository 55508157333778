/* Display flexs' */
.calendar-icon-wrapper {
  display: flex;
}

.date-input-container {
  position: relative;
}

.date-input-container > input {
  padding: 0.5rem 1rem;
  width: 100%;
}

/* Calendar icon */
.calendar-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 1rem;
  align-items: center;
}

.calendar-icon {
  cursor: pointer;
}

/* Calendar */
.calendar {
  position: absolute;
  z-index: 2;
  background: white;
  box-shadow: 4px 4px 10px rgba(122, 122, 122, 0.5);
  width: 25rem;
  text-align: center;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 0.3s;
  /* transform-origin: top left; */
}

.show-calendar {
  transform: scale(1);
}

.hide-calendar {
  transform: scale(0);
}

.calendar > div {
  padding: 1rem 1rem 0;
  /* padding-right: 3.2rem; */
}

.calendar > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar > div:last-child {
  padding: 0.85rem 1rem 1rem;
}

.calendar > div:first-child > button {
  font-size: 1rem;
  margin: 0 0.5rem;
}

/* Days */
.calendar > div :last-child > div > div > div > div {
  padding: 0.5rem 1rem;
}

/* Dates */
.calendar > div :last-child > div > div > div > button {
  padding: 0.5rem 1rem;
}

/* .calendar > div :last-child > div > div > div > button:hover {
  background: #ffffa9;
} */

.active-date {
  background: #006edc;
  color: white;
}

/* Indicator */
.invalid-date-indicator {
  color: red;
  padding: 0 0.5rem;
}

.tile {
  padding: 1rem;
  font-size: 0.9rem;
}
