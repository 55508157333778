/* Display flexs' */
.page-heading
{
  display: flex;
}

.page-select-payer
{
  min-height: 100vh;
  padding: 7rem 10rem 0;
}

.pd-wrapper
{
  border-radius: 1rem;
  overflow: hidden;
}

.pd-wrapper-heading
{
  padding: 0.85rem 1rem;
}

.dropbox
{
  height: 50rem;
}

.page-heading
{
  justify-content: space-between;
  margin-bottom: 1rem;
}

.page-heading > h2 > div
{
  padding-bottom: 0.5rem;
}