/* Display flexs' */
.download-btn-main
{
  display: flex;
}


.download-btn-main
{
  margin-top: 1rem;
  gap: 2rem;
  justify-content: center;
}

.download-btn-main > div
{
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.65rem;
  cursor: pointer;
}
