/* display flexs' */
.address-line-1,
.address-line-2,
.address-wrapper,
.city-state-wrapper,
.zip-country-wrapper,
.misc-detail-container{
    display: flex;
}

.payer-name-input-wrapper{
    margin: 0.5rem 0;
}


/* address block  */
.address-block{
    margin: 0.5rem 0;
}

/* .foreign-address-confirmation > input{
    transform: scale(1.25);
} */

.address-wrapper,
.city-state-wrapper,
.zip-country-wrapper{
    gap: 2rem;
}

.address-line-1,
.address-line-2,
.city-wrapper,
.state-wrapper,
.zip-wrapper,
.country-wrapper{
    flex-direction: column;
    width: 100%;
    margin: 0.25rem 0;
}

.zip-wrapper{
    width: 50%;
}

.country-wrapper{
    width: 50%;
}

/* personal info block  */
.misc-detail-container{
    gap: 2rem;
}

.misc-detail-container > div{
    margin: 0.5rem 0;
}

.email-wrapper{
    width: 40%;
}

.extn-wrapper{
    width: 20%;
}

.phone-wrapper{
    width: 30%;
}