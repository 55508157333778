@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .banner {
    @apply bg-taxeve-primary-violet text-white pt-36 pb-20 px-4 sm:px-80 text-center text-3xl;
  }
  .nested-div-in-banner {
    @apply pt-8 lg:pt-6 flex flex-col lg:flex-row justify-center gap-6;
  }
  .label {
    @apply font-bold;
  }
  .custom-card {
    @apply rounded-xl py-6 px-6;
    box-shadow: 4px 4px 20px rgba(122, 122, 122, 0.3);
  }
  .card-header {
    @apply text-2xl text-center font-bold;
  }
  .input-label {
    @apply py-2 font-bold;
  }
  .primary-button {
    @apply bg-taxeve-primary-violet text-white rounded-lg;
  }
  .secondary-button {
    @apply bg-yellow-500 text-white rounded-lg;
  }

  .page {
    @apply min-h-[100vh] relative;
  }

  .transparent {
    background-color: rgba(255, 255, 255, 0.8);
  }

  input,
  textarea {
    @apply focus:outline-taxeve-primary-violet;
  }

  h4,
  h3,
  h2,
  h1 {
    @apply font-bold;
  }
  h2 {
    @apply text-xl;
  }
  h1 {
    @apply text-2xl;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

input {
  border: 1px solid rgba(122, 122, 122, 0.7);
}

li {
  list-style: none;
}

/* Custom scroll bar  */
/* width */
::-webkit-scrollbar {
  width: 8px;
  position: fixed;
  left: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Remove arrows and spinners from input fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Input */
input {
  padding: 0.85rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

input[type="checkbox"] {
  margin-right: 0.5rem;
  transform: scale(1.15);
}

input[type="radio"] {
  margin-right: 0.25rem;
  /* transform: scale(1.25); */
}

label {
  color: rgba(0, 0, 0, 0.8);
}

.amount-input-container {
  position: relative;
}

.dollar-sign {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  padding: 1rem;
  font-size: 1.1rem;
}

.page-body {
  padding-top: 5rem;
}
