.fatca-filing-container {
  display: flex;
}

.fatca-filing-container > div:last-child {
  padding: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;
}
