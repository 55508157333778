/* Display flexs' */
.rating-main-container,
.star-list {
  display: flex;
}

.rating-main-container {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.star-list {
  justify-content: center;
  gap: 1rem;
}

.star-list > li > img {
  width: 100%;
}

.star-list > li {
  width: 3%;
}
