/* Display flexs' */
/* .template-downloader-main,
.instruction
{
  display: flex;
} */

.template-downloader-main
{
  min-height: 12rem;
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  padding: 0 0 1.5rem;
}

.instruction
{
  font-size: 1.2rem;
}

.instruction > ul
{
  padding: 0.5rem;
}

.instruction > ul > li
{
  margin: 0.5rem;
}

.instruction > ul > li > span
{
  font-weight: bold;
}

.instruction-heading
{
  padding: 0.5rem;
}