/* display flexs' */
.form-container,
.pop-up-container,
.email-input-container,
.pass-input-container,
.name-input-container,
.phone-input-container {
  display: flex;
}

.register-page-main {
  position: relative;
}

.form-container {
  justify-content: space-between;
  padding: 8rem;
  margin-top: 5rem;
}

.taxeve-img {
  width: 100%;
  height: 100%;
}

.taxeve-img-wrapper {
  width: 45%;
  height: 100%;
  /* box-shadow: 4px 4px 20px rgba(122, 122, 122, 0.3); */
}

/* login and register form  */
/*.login-form-container,*/
/*.register-form-container {
  width: 33rem;
}*/

.login-title,
.register-title {
  text-align: center;
}

.login-form,
.register-form {
  padding: 0;
}

.email-input-container,
.email-input-container > input,
.pass-input-container,
.pass-input-container > input,
.name-input-container,
.name-input-container > input,
.phone-input-container,
.phone-input-container > input {
  width: 100%;
}

.email-input-container > input,
.pass-input-container > input,
.name-input-container > input,
.phone-input-container > input {
  padding: 0.85rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
}

.email-input-container,
.pass-input-container,
.name-input-container,
.phone-input-container {
  /* margin: 1.5rem 0; */
  position: relative;
  align-items: center;
}

.email-input-container-wrapper,
.pass-input-container-wrapper,
.name-input-container-wrapper,
.phone-input-container-wrapper {
  padding: 0.8rem 0;
}

.pass-input-container-wrapper {
  position: relative;
}

.display-pass-container,
.input-logo {
  font-size: 1rem;
  /* color: #0079FF; */
  padding: 0.5rem 0;
  cursor: pointer;
  position: absolute;
  right: 0.85%;
  /* bottom: 0; */
  height: 93%;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  background: white;
  z-index: 2;
}

.input-logo {
  cursor: initial;
}

.login-btn-wrapper,
.register-btn-wrapper {
  text-align: center;
  margin: 1rem 0 0;
}

.login-btn,
.register-btn {
  border-radius: 2rem;
  padding: 1rem;
  width: 8rem;
  cursor: pointer;
  font-size: 1.2rem;
}

.register-btn {
  width: 12rem;
  border-radius: 1rem;
}

.forgot-password-container {
  text-align: center;
  padding: 0.8rem 0;
}

.register-redirect-container,
.login-redirect-container {
  text-align: center;
  font-size: 1.25rem;
}

.login-redirect-container {
  margin: 1rem 0;
}
