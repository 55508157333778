/* display flexs' */
.misc-detail-container-rec,
.recName-nameCont-container{
    display: flex;
}

.recipient-name-input-wrapper,
.name-contd-input-wrapper{
    margin: 0.5rem 0 0;
    padding: 0 1rem;
    width: 100%;
}

.recipient-details-input{
    width: 100%;
    padding: 0.5rem 1rem;
}

.recipient-address-block-heading{
    margin: 0 0 0.5rem;
}

.recipient-address-block{
    border-top-style: dashed;
    border-width: 2px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0 0;
}

.online-access-confirmation{
    padding: 0.25rem 1rem 0.5rem;
}

/* personal info block  */
.misc-detail-container-rec{
    gap: 2rem;
    padding: 0 1rem 0.5rem;
}

/* .misc-detail-container-rec > div{
    margin: 0.5rem 0;
} */

