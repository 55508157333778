.loader-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.loader {
  border: 15px solid #f3f3f3;
  /* border-top: 16px solid #5e3abb; */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
