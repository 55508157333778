/* Display flexs' */
.dropbox-container
{
  display: flex;
}

.dropbox-container
{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed black;
  height: 17rem;
  cursor: pointer;
}

.dropbox-text-wrapper
{
  text-align: center;
}

.dropbox-text
{
  padding: 0.5rem 0 0;
}

.allowed-file-types
{
  font-size: 0.85rem;
  padding: 0.5rem;
}