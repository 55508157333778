.property-service-indicator,
.foreign-transferor-indicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-service-indicator > input,
.foreign-transferor-indicator > input {
  margin: 0;
}

.property-service-indicator::after,
.foreign-transferor-indicator::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}
