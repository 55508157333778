/* pop up  */
.pop-up-container {
  width: 100%;
  position: fixed;
  top: 0;
  justify-content: center;
  z-index: 30;
  margin-top: 4rem;
}

.pop-up {
  width: fit-content;
  text-align: center;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}
