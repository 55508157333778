/* Display flexs' */
.modal-wrapper,
.most-liked-feature-container,
.improvement-container,
.testimonial-container,
.recommend-checkbox-container,
.consent-checkbox-container,
.feedback-submit-container,
.do-not-show-popup {
  display: flex;
}

.form-type-container {
  width: 20%;
  padding: 0.85rem 2rem 0;
}

.modal-wrapper {
  width: 100%;
  justify-content: center;
  padding: 2rem;
}

.modal {
  width: 80rem;
  /* height: 100%; */
}

.modal-heading {
  padding: 1rem;
}

.most-liked-feature-container,
.improvement-container,
.testimonial-container {
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem;
  width: 50%;
}

.recommend-checkbox-container,
.consent-checkbox-container {
  width: 50%;
  padding: 1rem 2rem;
  align-items: center;
  gap: 0.85rem;
}

.testimonial-container {
  width: 100%;
}

.testimonial-container > textarea {
  resize: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  height: 8rem;
  font-size: 1rem;
}

.do-not-show-popup {
  align-items: center;
}

.most-liked-feature-label,
.improvement-label,
.testimonial-label,
.recommend-checkbox-label,
.consent-checkbox-label {
  font-weight: bold;
}

.recommend-checkbox-label,
.consent-checkbox-label {
  padding-bottom: 0.3rem;
}

.do-not-show-popup-label {
  padding-bottom: 0.2rem;
}

#improvement,
#most-liked-feature {
  padding: 0.5rem 1rem;
}

.feedback-submit-container {
  justify-content: end;
  padding: 1rem 2rem 1.5rem;
  gap: 2rem;
}

.skip-btn,
.submit-feedback-btn {
  padding: 0.75rem 1.85rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
