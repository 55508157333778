.tin-block,
.rf-wrapper,
.rf-state-main {
  display: flex;
}

/* .rf-wrapper{
  min-height: 70%;
} */

.rf-main {
  width: 100vw;
  font-size: 0.9rem;
  padding: 0 1rem;
}

.rf-wrapper-container {
  padding: 2rem 0 0;
  height: 79vh;
  overflow: auto;
}

/* .rf-left-portion
{
  height: 100%;
} */

/* .rf-right-portion
{
  height: 7rem;
} */

/* all children components */
.rf-child-component {
  border-bottom: 1px solid black;
  padding: 0.5rem 1rem;
}

.rf-child-component > div:last-child {
  margin-top: 0.5rem;
}

.rf-pd {
  height: 33%;
}

.rf-pd > div:last-child > p {
  padding: 0.25rem 0;
}

/* Account component */
.ac-no-container {
  padding: 0.5rem 1rem;
  height: 100%;
}

.ac-no-container > div:last-child {
  margin-top: 0.5rem;
}

.rf-account-container {
  height: 100%;
}

/* Right portion */
.amounts {
  width: 66.75%;
}

/* Tin */
.tin-block {
  align-items: start;
}

.tin-container {
  padding: 0.5rem 1rem;
}

.tin-container > div:last-child {
  margin-top: 0.5rem;
}

/* Disclaimer */
.disclaimer {
  width: 33.25%;
  text-align: right;
  border-right: 1px solid black;
  padding: 0.5rem;
}

.disclaimer > h4 {
  margin-top: 0.25rem;
}

.disclaimer > p {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  line-height: 1.5;
}

.disclaimer > p > span {
  font-weight: bold;
}

/* Bold font */
.rf-wrapper {
  font-weight: bold;
}

/* State component */

.tax-amt {
  padding: 0.85rem 1rem;
  height: 3.72rem;
}

/*Left side tax amount*/
.tax-amt-left-side {
  height: 3.45rem;
}

.rf-si-heading {
  font-weight: bold;
  padding: 0.25rem 1rem;
}

.rf-state-number {
  width: 15rem;
}

.rf-amt-child {
  width: 15rem;
}
