/* display flexs'  */
.phone,
.email,
.phone > h3,
.email > h3 {
  display: flex;
}

.contact-creds-container {
  align-items: center;
  gap: 2rem;
}

.phone,
.email {
  gap: 0.5rem;
}

.phone > h3:last-child,
.email > h3:last-child {
  font-weight: lighter;
}

.phone > h3,
.email > h3 {
  align-items: center;
}
