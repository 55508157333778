/* display flexs' */
.navbar-container {
  display: flex;
}

.navbar-container {
  justify-content: space-between;
  box-shadow: 0 5px 10px rgba(122, 122, 122, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  /* z-index: 5; */
}

/* Account related components */
.account-related-component-container {
  align-items: center;
  gap: 1.5rem;
}
