/* Display flexs' */
.state-details-container,
.state-stateNo-container {
  display: flex;
}

.state-details-container {
  justify-content: center;
  height: 100%;
}

.state-info-box {
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.state-info-heading {
  padding: 0.5rem 1rem;
  background: #f5f5f5;
  border-radius: 0.5rem 0.5rem 0 0;
}

.state-info-heading-2 {
  border-radius: 0;
}

.state-info-clear-btn {
  text-align: right;
  padding: 0 1rem 0.5rem;
  color: red;
}

.state-info-clear-btn > button {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

/* State details */
.state-details-container {
  padding: 1rem;
}

.state-details-container > div > div > label {
  font-size: 1.1rem;
}

.state-container,
.state-number-container {
  width: 100%;
}

.state-tax-withheld-container,
.state-stateNo-container,
.state-income-container {
  border: 1px solid black;
  padding: 0.5rem 1rem;
}

.state-stateNo-container {
  width: 50%;
  align-items: center;
  gap: 1rem;
  border-left: none;
  border-right: none;
}

.state-tax-withheld-container,
.state-income-container {
  width: 25%;
}
