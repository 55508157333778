/* display flexs' */
.recipient-tin-heading-wrapper,
.tin-type{
    display: flex;
}

.recipient-tin-heading-wrapper{
    gap: 2rem;
    align-items: center;
}

.tin-type{
    gap: 0.5rem;
}

.recipient-tin-container{
    padding: 0.85rem 1rem;
}