/* Display flexs' */
.form-main-container,
.form-details-amount-wrapper,
.top-section,
.payment-amounts-container,
.payer-direct-sales,
.amount-container {
  display: flex;
}

.amount-container {
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  width: 50%;
  gap: 0.5rem;
}

.form-details-amount-wrapper {
  width: 100%;
}

.left-portion-details,
.right-portion-details {
  width: 50%;
}

.form-main-container {
  padding: 6.5rem 5rem 1rem; /*display below the navbar*/
  flex-direction: column;
  flex-wrap: wrap;
}

.form-wrapper {
  padding: 2rem;
}

.misc-info,
.payer-direct-sales {
  width: 100%;
}

.payer-direct-sales {
  align-items: "center";
  gap: 1rem;
}

/* Payer input fields */
.payer-details-input {
  width: 100%;
  padding: 0.5rem 1rem;
}

/* Right portion details  */
.heading {
  padding: 0 0 0.5rem;
}

.amount-input {
  text-align: right;
}

.top-section {
  border-bottom: 1px solid black;
}

/* Blank */
.blank {
  padding: 0.5rem 1rem;
}

/* Calendar */
.move-calendar-to-left {
  left: -6.5rem;
  transform-origin: top right;
}

.display-from-top-left {
  transform-origin: top left;
}
