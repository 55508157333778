/* display flexs' */
.payer-tin-heading-wrapper{
    display: flex;
}

.payer-tin-heading-wrapper{
    gap: 2rem;
    align-items: center;
}


.tin-type > div{
    padding: 0.5rem 1rem;
    border-radius: 2rem;
}