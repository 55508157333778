.second-tin-notice {
  flex-direction: column;
}

.second-tin-notice {
  padding: 1.5rem 1rem 0;
  align-items: center;
  justify-content: start;
  text-align: center;
  gap: 0.5rem;
}

.second-tin-notice > div:first-child {
  width: 8rem;
}

.checkbox {
  width: 100%;
}
