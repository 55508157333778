.page-not-found-container {
  background-image: url("/public/Assets/404.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  display: flex;
  justify-content: center;
}

.home-btn {
  position: absolute;
  bottom: 10rem;
  padding: 1rem 2rem;
  border-radius: 0.85rem;
  font-size: 1.25rem;
}
