/* Display flexs' */
.settings-name > h2,
.profile-settings-list > li > p,
.account-settings-body {
  display: flex;
}

.account-settings-body {
  height: 100vh;
}

.settings-list {
  padding: 2.5rem 0;
  width: 15rem;
  height: 100%;
}

.settings-name {
  padding: 0.5rem;
  font-size: 0.85rem;
}

.settings-name > h2 {
  align-items: center;
  gap: 0.5rem;
}

.setting {
  padding: 2.5rem 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

/* Profile settings  */
.profile-settings-list > li {
  padding: 0.85rem;
  cursor: pointer;
}

.profile-settings-list > li > p {
  align-items: center;
  gap: 0.5rem;
}

/* Update button */
.update-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
