.payment-btn {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-top: 4rem;
}

.payment-btn:hover {
  opacity: 0.8;
}
