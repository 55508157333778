/* display flexs' */
.user-details,
.logout-container {
  display: flex;
}

.popup-main-container {
  position: absolute;
  left: -20.5rem;
  bottom: -16.85rem;
  width: 27rem;
  padding: 0.5rem 0;
  border-radius: 0.85rem;
}

.user-details {
  padding: 0.5rem 1.5rem;
  align-items: center;
  justify-content: space-between;
}

.user-email {
  opacity: 0.45;
}

.account-settings-btn-container {
  text-align: center;
  padding: 0.5rem 1rem;
}

.account-settings-btn {
  padding: 0.5rem 2rem;
  width: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.logout-container {
  padding: 0.85rem 2rem;
  font-size: 1.25rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid white;
  position: absolute;
  top: -1.2rem;
  right: 4rem;
}
