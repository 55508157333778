.rp-btn{
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rp-btn > h1,
.rp-btn > h3{
  display: flex;
  align-items: center;
}

.rp-btn > h3{
  font-size: 1.05rem;
}

.rp-container
{
  position: relative;
}