/* Display flexs' */
.new-fullname-wrapper,
.fullname-setting-main,
.fullname-setting-left-portion {
  display: flex;
}

.fullname-setting-main {
  padding: 0 1rem;
}

.fullname-setting-left-portion {
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

.new-fullname-wrapper {
  flex-direction: column;
  gap: 0.5rem;
}

.new-fullname-input {
  width: 30rem;
  padding: 0.5rem 1rem;
}

.fullname-change-btn {
  padding: 0.65rem;
  border-radius: 0.5rem;
  width: 12rem;
  font-size: 1rem;
}
