/* Display flexs' */
.cl-wrapper,
.cart-page-subheader {
  display: flex;
}

.cl-wrapper {
  flex-direction: column;
  gap: 1rem;
  width: 65%;
  padding: 3rem 1rem;
  position: relative;
}

.cart-page-heading {
  text-decoration: underline;
}

.cart-page-subheader {
  justify-content: space-between;
  align-items: center;
}

.bulk-payer-selection-text {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  cursor: pointer;
}

/* Tin mask container */
.tin-mask-container-wrapper {
  width: 15%;
  padding: 1rem;
}

/* Order details */
/* .order-details-container
{
    width: 30rem;
} */
