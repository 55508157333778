.pass-instruction-container-main{
    position: absolute;
    bottom: -14.5rem;
    width: 100%;
    height: 15rem;
    padding: 1rem 0.8rem;
    box-shadow: 2px 2px 10px rgba(122, 122, 122, 0.3);
    border-radius: 0.5rem;
}

.pass-instruction-container-main > h4{
    color: rgb(81, 81, 81);
}

.instructions-container{
    padding: 0.5rem 0.7rem;
    font-size: 0.93rem;
}

.instructions-container > li:first-child{
    margin-top: 0;
}

.instructions-container > li{
    margin: 1rem 0;
    color: rgba(0, 0, 0, 0.5)
}

.instructions-container > li > span{
    color: red;
}