.action-popup-main
{
    position: absolute;
    z-index: 5;
    /* top: -1.5rem;
    right: -5.5rem; */
    left: -0.25rem;
    border-radius: 0.25rem;
    overflow: hidden;
}

.action-popup-main > div
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}

/* .action-popup-main>div:hover
{
    background: #F9D949;
} */

.action-popup-main > div > p
{
    width: 100%;
    text-align: left;
}

/* .action-popup-main > div:first-child
{
    margin-bottom: 0.25rem;
} */