.submit-btn-container{
    padding: 2rem 4rem;
    text-align: center;
}

.submit-btn{
    margin: 0 1rem;
    padding: 1rem 2rem;
    font-size: 1.15rem;
    border-radius: 0.5rem;
}

