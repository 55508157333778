.indicator-container-main{
    color: red
}

.indicator-container-main > div > span{
    color: red;
}

.indicator-container-main > div{
    padding: 0.25rem 0 0;
}