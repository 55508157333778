/* Display flexs' */
.current-email-wrapper,
.new-email-wrapper,
.email-setting-main,
.email-setting-left-portion {
  display: flex;
}

.email-setting-main {
  padding: 0 1rem;
}

.email-setting-left-portion {
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

.current-email-wrapper,
.new-email-wrapper {
  flex-direction: column;
  gap: 0.5rem;
}

.current-email-input,
.new-email-input {
  width: 30rem;
  padding: 0.5rem 1rem;
}
