/* display flexs' */
.forms-container,
.form1099-wrapper {
  display: flex;
}

.form1099-main-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.form1099-main-container > div > h3 {
  padding: 0.8rem 2rem;
  background: linear-gradient(to right, rgb(172, 96, 208), rgba(172, 96, 208, 0.55));
}

.form1099-main-container > div {
  box-shadow: 3px 3px 15px 5px #f0f0f0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.forms-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Individual form  */
.form1099-container {
  width: 50%;
  padding: 0 2rem;
}

.form1099-wrapper {
  padding: 2rem 0;
  border-bottom: 2px solid rgba(122, 122, 122, 0.3);
  justify-content: space-between;
  align-items: center;
}

.form1099-description {
  color: rgba(0, 0, 0, 0.4);
}

.filing-btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.95rem;
  cursor: pointer;
  border-radius: 0.3rem;
}

/* test  */
.form-title-desc {
  width: 70%;
}

@media only screen and (max-width: 800px) {
  /*.form1099-main-container {
    padding: 0;
  } */

  .forms-container {
    flex-direction: column;
  }

  .form1099-container {
    width: 100%;
  }
}
