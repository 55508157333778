.outline-font {
  font-family: "Londrina Outline", cursive;
}

.fill-font {
  font-family: "Yantramanav", sans-serif;
}

.taxyear-new-font-main {
  /* width: 100%; */
  display: flex;
  align-items: center;
}

.taxyear-new-font-main > span:first-child {
  font-size: 1.25rem;
}

.taxyear-new-font-main > span:last-child {
  font-size: 1.43rem;
  padding-top: 0.15rem;
}

.taxyear-new-font-main > span > h1 {
  height: 100%;
  letter-spacing: 0.5rem;
}
