.password-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.password-input {
  width: 30rem;
  padding: 0.5rem 1rem;
}
