/* Display flexs' */
/* .confirm-btn-main {
  display: flex;
} */

.confirm-btn-main {
  justify-content: end;
  gap: 1rem;
  padding: 1rem;
}

.confirm-btn-main > button {
  padding: 0.85rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
