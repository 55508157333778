/* display flex */
.form-detail-container,
.form-name,
.form-type-tax-year,
.form-type,
.form-type-wrapper,
.tax-year-wrapper {
  display: flex;
}

/* .form-detail-container{
    width: 65%;
} */

.form-name {
  align-items: center;
  text-align: center;
  padding: 2rem 0;
  width: 11rem;
}

.form-type-tax-year {
  width: 12rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.form-type-wrapper,
.tax-year-wrapper {
  height: 50%;
  align-items: center;
  justify-content: center;
}

.form-type-wrapper {
  padding: 1rem 0;
}

.form-type {
  padding: 0.5rem;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.form-type > h3 {
  font-size: 1.25rem;
}

.tax-year {
  padding: 0.5rem;
}
