.erm-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  /* opacity: 0.6; */
  z-index: 30;
  display: flex;
  justify-content: center;
}
