/* Form table */
.form-table-container {
  padding: 0 2rem;
}

.table-heading {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

/*.action-wrapper {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  gap: 1rem;
}*/

/*.preview,
.download {
  width: 50%;
}*/

/*.preview {
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}*/
