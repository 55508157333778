/* Display flexs' */
.new-phonenumber-wrapper,
.phone-setting-main,
.phone-setting-left-portion {
  display: flex;
}

.phone-setting-main {
  padding: 0 1rem;
}

.phone-setting-left-portion {
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

.new-phonenumber-wrapper {
  flex-direction: column;
  gap: 0.5rem;
}

.new-phonenumber-input {
  width: 30rem;
  padding: 0.5rem 1rem;
}

.phonenumber-change-btn {
  padding: 0.65rem;
  border-radius: 0.5rem;
  width: 13rem;
  font-size: 1rem;
}
