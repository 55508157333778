.cart-container{
  position: relative;
}

.total-cart-items{
  position: absolute;
  right: -1rem;
  top: -0.5rem;
  padding: 0 0.3rem 0.2rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.cart-icon{
  font-size: 2.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}