/* Display flexs' */
.modal-card-body,
.modal-action-btn-container
{
    display: flex;
}

.email-required-card-main
{
    position: fixed;
    top: 5rem;
    width: 50rem;
    border-radius: 0.85rem;
}

.top-panel
{
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 0.85rem 0.85rem 0 0;
}

.modal-card-body
{
    padding: 1rem;
    max-height: 30rem;
    overflow: auto;
    flex-direction: column;
    gap: 1rem;
}

.modal-card-body-header
{
    font-size: 1.15rem;
}

.modal-action-btn-container
{
    text-align: right;align-items: center;
    justify-content: end;
    gap: 1rem;
}

.modal-action-btn-container > button
{
    padding: 0.85rem 1.5rem;
    font-size: 1.025rem;
    border-radius: 0.5rem;
}

.rec-skip-text
{
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    cursor: pointer;
}

/* .modal-action-btn-container > button:last-child
{
    margin-left: 1rem;
} */