/* display flexs' */
.email-activation-container {
  display: flex;
}

.email-activation-container {
  flex-direction: column;
  align-items: center;
  padding: 0 0 2rem;
}

/*.email-activation-main{
    width: 33rem;
}*/

.email-activation-img {
  width: 70%;
  height: 70%;
}

.email-activation-img-container {
  width: 25%;
  height: 100%;
}

.email-activate-msg-container {
  padding: 2rem 0;
  text-align: center;
}

.resend-btn-container {
  width: 100%;
  text-align: center;
}

.resend-btn-container > button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 1rem;
}

.resend-timer-text {
  font-size: 1rem;
  padding: 0.5rem;
}

.redirect-text-container {
  padding: 2rem;
}
