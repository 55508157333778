.rec-info {
    padding: 20px;
}

.rec-info>td {
    width: 17rem;
}

#POSTAL_MAILING,
#ONLINE_ACCESS,
#STATE_FILING {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

/* #ONLINE_ACCESS {
    justify-content: space-between;
    padding: 0 2rem;
} */

#STATE_FILING {
    align-items: center;
    font-weight: bold;
}

#POSTAL_MAILING>span,
#ONLINE_ACCESS>span {
    text-align: left;
    cursor: pointer;
}

#POSTAL_MAILING>span {
    width: 6rem;
}

#ONLINE_ACCESS>span {
    width: 9rem;
}

#STATE_FILING>span {
    width: 6rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.td-content
{
    font-size: 0.9rem;
}

.action-dots-wrapper
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-dots
{
    cursor: pointer;
}


/* .td-checkbox
{
    font-size: 0.9rem;
} */

/* #POSTAL_MAILING>span {
    cursor: pointer;
    width: 6rem;
    text-align: left;
}

#ONLINE_ACCESS>span {
    width: 20rem;
} */

/* #POSTAL_MAILING {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

} */