/* Display flexs' */
.filer-payment-container {
  display: flex;
}

.filer-type-container,
.filer-payment-type-container {
  padding: 1rem;
  width: 50%;
  font-weight: bold;
}

.filer-type-container > div:first-child,
.filer-payment-type-container > div:first-child {
  /* font-size: 0.85rem; */
  width: 80%;
}

.pse-wrapper,
.epf-wrapper,
.payment-card-wrapper,
.third-party-network-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}
